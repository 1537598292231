import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import { IRoomTv } from 'src/features/home/interfaces/home.interfaces'
import api from '@/common/helpers/api.helpers'
import { Method } from 'axios'

type CustomBaseQuery = BaseQueryFn<{ url: string; params?: any; method?: Method; data?: any }, unknown, unknown>

const customBaseQuery: CustomBaseQuery = async ({ url, params, method = 'GET', data }) => {
  try {
    const baseURL = process.env.REACT_APP_RESIDEN_API_BASE_URL
    const result = await api(method, `${baseURL}${url}`, method === 'GET' ? params : data)
    return { data: result.data }
  } catch (error: any) {
    return { error }
  }
}

export const homeAPi = createApi({
  reducerPath: 'homeAPi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getListRoomsWithTvs: builder.query<IRoomTv[], { doctor_id: number; slot_id: number }>({
      query: (params) => ({
        url: `/rooms-tv-notify/${params.doctor_id}/${params.slot_id}`,
        method: 'GET',
        params,
      }),
    }),
    announceRoomsTVs: builder.mutation<void, { hardware_ids: number[]; message: string; number_of_dings: number }>({
      query: (data) => ({
        url: `/rooms-tv-notify`,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const { useGetListRoomsWithTvsQuery, useAnnounceRoomsTVsMutation } = homeAPi
