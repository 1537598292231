import { SortsType, TableParamsType } from 'src/common/interfaces/fancy-table.interfaces'

export const formatSortersAsParams = (sorters: SortsType) => {
  const sortArray = Object.keys(sorters)
    .map((key) => ({
      key,
      ...sorters[key],
    }))
    .sort((a, b) => (a.multiple || 0) - (b.multiple || 0))

  const performedArr = sortArray.map((item) => {
    const sign = item.order === 'descend' ? '-' : ''
    return sign + item.key
  })

  return performedArr.join(',')
}

const badValues = [undefined, null, '']

export const buildPaginationQuery = (
  baseURL: string,
  params: TableParamsType,
  otherParams: Record<string, any> = {},
): string => {
  const searchParams = new URLSearchParams()

  searchParams.set('per_page', (params.pageSize ?? 25).toString())
  searchParams.set('page', (params.current ?? 1).toString())

  if (params?.sorters && Object.keys(params.sorters).length > 0) {
    const sortParam = formatSortersAsParams(params.sorters)
    searchParams.set('sort', sortParam)
  }

  Object.entries(otherParams).forEach(([key, value]) => {
    if (!badValues.includes(value)) {
      searchParams.set(key, value)
    }
  })

  return `${baseURL}?${searchParams.toString()}`
}
